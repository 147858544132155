<template>
  <div class="elv-component-custom-table-panel">
    <div class="elv-component-custom-table-panel-content-container">
      <el-table
        :data="tableData"
        v-bind="$attrs"
        height="100%"
        class="elv-component-custom-table"
        header-cell-class-name="elv-component-custom-table-header__cell"
        header-row-class-name="elv-component-custom-table-header"
        row-class-name="elv-component-custom-table-row"
        cell-class-name="elv-component-custom-table-row__cell"
        style="width: 100%"
      >
        <el-table-column v-if="isMutileSelect" type="selection" width="40" :disabled="true" reserve-selection />
        <template v-for="item in tableHeader" :key="item.dataIndex">
          <el-table-column
            v-if="item.slotName || item.render"
            :show-overflow-tooltip="item.showOverflowTooltip || false"
            :prop="item.dataIndex"
            :label="transformI18n(item.title)"
            :sortable="item.sortable"
            :align="item.align || undefined"
            :width="item.width || undefined"
            :fixed="item.fixed || undefined"
            :min-width="item.min_width || undefined"
            :header-align="item.headerAlign || undefined"
          >
            <template v-if="item.isCustomHeader" #header>
              <slot :name="item.headerSlotName"></slot>
            </template>
            <template #default="scope">
              <div v-if="item.render">
                {{ typeof item.render === 'function' ? (item as any).render({ data: scope.row }) : item.render }}
              </div>
              <slot v-else :name="item.slotName" :data="scope.row"></slot>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            show-overflow-tooltip
            :prop="item.dataIndex"
            :label="item.title"
            :sortable="item.sortable"
            :align="item.align || undefined"
            :width="item.width"
            :fixed="item.fixed || undefined"
            :min-width="item.min_width || undefined"
          />
        </template>
        <slot />
      </el-table>
    </div>
    <elv-pagination
      class="elv-transactions-list-pagination"
      size-popper-class="elv-transactions-pagination__sizes-popper"
      :hide-on-single-page="hideSinglePage"
      :limit="paginationData.limit"
      :current-page="paginationData.page"
      :total="tableCountNumber || tableData.length"
      @current-change="onChangePage"
      @size-change="onChangePageSize"
    />
  </div>
</template>

<script lang="ts" setup>
import { VNodeChild } from 'vue'
import { transformI18n } from '@/i18n/index'

interface tableHeaderItem {
  dataIndex: string
  title: string
  slotName?: string
  align?: string
  width?: string
  sortable?: boolean
  render?: VNodeChild
  fixed?: string
  min_width?: string
  headerAlign?: string
  showOverflowTooltip?: boolean
  isCustomHeader?: boolean
  headerSlotName?: string
}
defineProps({
  tableData: {
    type: Array<any>,
    default: () => []
  },
  tableHeader: {
    type: Array as () => tableHeaderItem[],
    default: () => []
  },
  paginationData: {
    type: Object,
    default: () => {}
  },
  tableHeight: {
    type: String,
    require: true,
    default: '100%'
  },
  isMutileSelect: {
    type: Boolean,
    default: false
  },
  tableCountNumber: {
    type: Number,
    default: 0
  },
  hideSinglePage: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['onChangePage', 'onChangePageSize'])

const onChangePage = (page: number) => {
  emits('onChangePage', page)
}

const onChangePageSize = (limit: number) => {
  emits('onChangePageSize', limit)
}
</script>
<style lang="scss">
.elv-component-custom-table {
  --el-table-border-color: #d4dce5;
  --el-table-border: 1px solid #edf0f3;
  --el-fill-color-lighter: #f9fafb;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #d4dce6;
  border-right: 0px;
  border-left: 0px;
  border-bottom-width: 1px;

  .el-table__inner-wrapper {
    width: 100%;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  &.el-table--border {
    border-bottom-color: #edf0f3;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__body-wrapper {
    .el-scrollbar__view {
      width: 100%;
      margin-bottom: 0px !important;
    }
  }

  .elv-component-custom-table-header {
    background: #eef4fb;

    .elv-component-custom-table-header__cell {
      background: #eef4fb !important;
      height: 36px;
      box-sizing: border-box;
      border-right-color: #e4e7eb;
      border-bottom-color: #ced7e0;
      padding: 0px;

      &.is-right .cell {
        justify-content: flex-end;
      }

      .cell {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-items: center;
      }
    }
  }

  .elv-component-custom-table-row__cell {
    padding: 0;
    height: 42px;

    &:last-child {
      border-right: 0px;
    }

    .cell {
      padding: 0 10px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #0e0f11;
      line-height: 16px;
    }

    &.is-right .cell {
      text-align: right;
    }

    &.is-center .cell {
      text-align: center;
    }
  }

  .el-table__footer-wrapper {
    .el-table__footer {
      tr > td {
        height: 44px;
        padding: 0px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.elv-component-custom-table-panel {
  height: 100%;
  width: 100%;
  position: relative;

  .elv-component-custom-table-panel-content-container {
    height: calc(100% - 54px);
    position: relative;
  }
}
</style>
